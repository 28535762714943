<template>
  <a-layout class="apps-layout-con">
    <a-layout-header class="apps-layout-header flex flex-j-c-sb">
      <div class="header-left-con">
        <div style="display: flex">
          <img :src="$logo || '/images/logo.png'" v-if="!isBreakpoint" height="28" alt="Logo" @click="backToHome" style="cursor:pointer;">
          <img :src="$logo || '/images/simple-logo.png'" v-if="(breakpoint > breakpointList.xs) && (breakpoint < breakpointList.lg)" height="28" alt="Logo" @click="backToHome" style="cursor:pointer">
          <span class="divide-line" v-if="!isBreakpoint"></span>
        </div>
        <div style="line-height:initial" v-if="!expired">
          <span class="home-page grey-button" @click="backToHome">
            <span><HomeOutlined /></span>
            <span style="margin-left: 6px" v-if="!isBreakpoint">{{$t('apps.mainPage')}}</span>
          </span>
        </div>
        <div class="share-title" v-if="expired">
          鹰眼分享
        </div>
        <div style="line-height:initial" v-if="projectName">
          <span class="project-selection grey-button" style="cursor: default;">
            <span>{{projectName}}</span>
          </span>
        </div>
      </div>
      <div class="header-right-con">
        <HeaderAction :isBreakpoint="isBreakpoint" :showAvater="false"/>
      </div>
    </a-layout-header>
    <a-layout style="display: none;">
      <a-layout-sider
        :width="0"
        class="client-layout-sider"
        breakpoint="lg"
        collapsed-width="0"
        @breakpoint="onBreak">
      </a-layout-sider>
    </a-layout>
    <a-layout class="apps-layout-content-wrap">
      <a-layout-content class="apps-layout-content">
        <!-- <router-view/> -->
        <a-spin tip="Loading..."
          style="position: absolute;left: 50%;top: 50%;z-index:10;transform: translate(-50%, -50%);"
          v-show="showLoading">
        </a-spin>
          <div id="app-container">
            <Result title="此分享链接已过期" class="center-result" v-if="expired">
              <template #extra>
                <Space>
                  <Button type="primary" @click="backToHome">
                    返回鹰眼首页
                  </Button>
                </Space>
              </template>
            </Result>
          </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { computed, defineComponent, onMounted, onUnmounted, reactive, toRefs } from 'vue'
import {
  HomeOutlined
  // StarOutlined,
  // StarFilled
  // BellOutlined
  // TeamOutlined,
  // UserAddOutlined
} from '@ant-design/icons-vue'
import { useRoute, useRouter } from 'vue-router'
import { client } from '@/router/path'
import dayjs from 'dayjs'
import * as localizedFormat from 'dayjs/plugin/localizedFormat'
import HeaderAction from '@/components/business/HeaderAction.vue'
import {
  loadMicroApp,
  addGlobalUncaughtErrorHandler
  // , registerMicroApps, start
} from 'qiankun'
import actions, { globalStateChange } from '@/qiankun/action'
import { mapState, useStore } from 'vuex'
import config from '@/config'
import AMap from 'AMap'
import api from '@/services/api'
import { USERS } from '@/store/type'
import { Result, Space, Button } from '@arco-design/web-vue'
import '@arco-design/web-vue/lib/result/style/css'
import '@arco-design/web-vue/lib/space/style/css'
import '@arco-design/web-vue/lib/button/style/css'
// import 'zone.js'
export default defineComponent({
  name: 'index',
  components: {
    // UserAddOutlined,
    // TeamOutlined,
    HomeOutlined,
    // StarOutlined,
    // StarFilled,
    HeaderAction,
    Result,
    Space,
    Button
    // BellOutlined
  },
  props: {
    shareId: String
  },
  computed: {
    ...mapState([
      'breakpoint',
      'breakpointList'
    ])
  },
  setup (props, context) {
    addGlobalUncaughtErrorHandler(event => console.error('子应用异常', event))
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    // const store = useStore()
    dayjs.extend(localizedFormat)

    let state = reactive({
      toDate: '',
      isBreakpoint: false,
      showLoading: false,
      microApp: null,
      basePath: 'https://dev-ocean-eagle.jarvisbim.com.cn/',
      projectName: null,
      expired: false
    })

    let iTimer = null

    const currentProject = computed(() => {
      const filter = state.projectList.filter(item => {
        return item.projectId === route.params.projectId
      })
      return filter && filter[0] ? filter[0] : null
    })
    // let microApp = null

    const checkArea = (isInland) => {
      const search = window.location.search && window.location.search.length ? window.location.search : null
      const jumpUrl = config[isInland ? 'INLAND_URL' : 'FOREIGN_URL'] + window.location.pathname + `${search ? search + '&' : '?'}token=${store.state.token}`
      // window.open(jumpUrl, '_self')
      window.location.replace(jumpUrl)
    }

    const getCity = () => {
      const citySearch = new AMap.CitySearch()
      citySearch.getCityByIp((status, result) => {
        let isInland = false
        if (status === 'complete' && result.info === 'OK') {
          if (result && result.adcode && config.FOREIGN_LIST && !(config.FOREIGN_LIST.includes(result.adcode))) {
            isInland = true
          }
        } else {
          return
        }
        const local = window.location.origin
        if (!config.FOREIGN_URL || !config.INLAND_URL || !config.FOREIGN_LIST) return
        if ((!isInland && (local !== config.FOREIGN_URL)) || (isInland && (local === config.FOREIGN_URL))) {
          checkArea(isInland)
        }
      })
    }
    onMounted(async () => {
      getCity()
      window.addEventListener('beforeunload', async e => {
        if (state.microApp) {
          await state.microApp.unmount()
          state.microApp = null
        }
        state = null
        window.removeEventListener('beforeunload', () => {})
      })
      init()
    })

    const init = async () => {
      initEagle()
      // state.activeKey = [route.name]
    }

    const initEagle = async () => {
      // router.replace(key)
      state.showLoading = true
      if (props.shareId) {
        const res = await api.share.getShareDetail({
          shareId: route.params.shareId
        })
        if (res && (res.code === 20007 || res.code === 20004)) {
          state.expired = true
        }
        if (!(res && res.code === 200)) {
          state.showLoading = false
          return
        }
        state.projectName = res.data?.projectName
        // 加载微应用
        if (state.microApp) {
          window.location.reload()
          await state.microApp.unmount()
          state.microApp = null
        }
        /* 查找需要用到的配置 */
        // 查找微应用路径的配置
        // const entryFilter = element.configs.filter(i => i.entitySign === 'entry')
        const entryFilter = 'eagleApp/'
        if (entryFilter) {
          const entryUrl = (config.BASE_APP_PATH || state.basePath) + 'eagleApp/'
          // debugger
          const props = {
            ...route.params,
            ...route.query,
            locale: store.state.locale,
            publicPath: config.DEVELOPMENT ? config.DEBUGGER_APP_URL.split('#/')[0] : entryFilter.split('#/')[0],
            shareData: res.data
          }
          state.microApp = loadMicroApp({
            name: 'eagleApp', // 应用标识
            // entry: entryUrl,
            entry: config.DEVELOPMENT ? config.DEBUGGER_APP_URL : entryUrl, // 应用入口
            container: '#app-container', // 容器
            props //  子应用加载时传给子应用的参数
          }, {
            sandbox: {
              experimentalStyleIsolation: true // 当 experimentalStyleIsolation 被设置为 true 时，qiankun 会改写子应用所添加的样式为所有样式规则增加一个特殊的选择器规则来限定其影响范围
            }
          })

          // nextTick(() => {
          //   console.log()
          //   router.replace({
          //     path: '/' + route.params.orgId + '/' + route.params.projectId + '/' + element.groupSign,
          //     query: route.query
          //   })
          // })

          // 微应用加载成功时把loading去掉
          let count = 0
          iTimer = setInterval(() => {
            const status = state.microApp.getStatus()
            console.log(status)
            count++
            if (status === 'MOUNTED' || count >= 20) {
              state.showLoading = false
              clearInterval(iTimer)
              iTimer = null
            }
          }, 500)

          // onGlobalStateChange(监听全局状态，有变更触发 callback)
          globalStateChange((s, prev) => {
          // 子应用传来通知 Token失效
          // debugger
          // console.log(s)
            if (s.is401) {
              store.dispatch('logout')
            }
          })
          const query = JSON.parse(JSON.stringify(route.query))
          delete query.annotationId
          router.replace({ query })
        }
      }
    }

    const onBreak = (val) => {
      state.isBreakpoint = val
    }

    const backToHome = () => {
      store.dispatch('user/' + USERS.SET_USER_INFO, {
        callback: (orgId) => {
          router.push({
            name: client.PROJECT_LIST,
            params: {
              orgId: orgId
            }
          })
        },
        noOrgIdCallback: () => {
          router.push({
            name: 'Login'
          })
        }
      })
    }

    onUnmounted(async () => {
      // window.document.createElement = Document.prototype.createElement
      if (state.microApp) {
        await state.microApp.unmount()
        state.microApp = null
      }
      actions.offGlobalStateChange()
      if (iTimer) {
        clearInterval(iTimer)
        iTimer = null
      }
      state = null
    })

    return {
      currentProject,
      onBreak,
      backToHome,
      ...toRefs(state),
      route
    }
  }
})
</script>
<style lang='less'>
#app-container > div {
  width: 100%;
  height: 100%;
  &.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &.center-result {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.apps-layout-con {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  user-select: none;
  .apps-layout-header {
    padding: 0 20px;
    width: 100%;
    height: 64px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 9;
    .header-left-con {
      display: flex;
      align-items: center;
      width: calc(100% - 20px);
      .share-title {
        margin-left: 8px;
        font-size: 16px;
        font-weight: 600;
      }
      .divide-line {
        height: 28px;
        border-left: solid 1px rgba(0,0,0,0.2);
        margin-left: 4px;
      }
      .ant-menu {
          width: calc(100% - 100px);
          min-width: 20px;
      }
      &-lg {
        width: calc(100% - 180px);
        .ant-menu {
          width: calc(100% - 50px);
        }
      }
      .home-page {
        margin: 0 8px;
      }
      .project-selection {
      }
      .grey-button {
        padding: 4px 8px;
        white-space: nowrap;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        // text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
        background: rgba(0, 0, 0, 0.05);
        // box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      .app-list {
        .ant-menu-item {
          .tab-text {
            // padding-right: 26px;
          }
          .collection-icon {
            margin-left: 5px;
            cursor: pointer;
            position: absolute;
            line-height: 14px;
            top: 50%;
            // right: 26px;
            transform: translateY(-50%);
          }
          &:hover {
            .collection-icon {
              .non-collect-icon {
                color: black;
              }
              display: inline-block;
            }
          }
        }
        .ant-menu-item-active {
          .tab-text {
            color: #008CFF;
          }
        }
        .non-f {
          display: none;
        }
      }
    }
    .header-right-con {
      display: flex;
      align-items: center;
      .header-icon {
        margin-left: 24px;
        cursor: pointer;
      }
      .header-icon-lg {
        margin-left: 8px;
      }
      .team-button {
        display: flex;
        white-space: nowrap;
        line-height: initial;
        border: solid 1px #008CFF;
        border-radius: 5px;
        margin-left: 25px;
      }
      .team-member {
          padding: 4px 8px;
          color: #008FFC;
          cursor: pointer;
      }
      span.team-icon {
          margin-right: 4px;
      }
      .member-invite {
          padding: 4px 8px;
          background: #008FFC;
          color: white;
          cursor: pointer;
      }
    }
  }
  .apps-layout-content-wrap {
    // padding-top: 10px;
    width: 100%;
    height: calc(100% - 64px);
    position: relative;
    overflow: hidden;
    .apps-layout-content {
    //   padding: 0 20px;
      background: #fff;
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      #app-container {
        width: 100%;
        height: 100%;
        position: relative;
        top: 1px;

      }
      .close-icon {
        cursor: pointer
      }
      .member-con {
        .add-member {
          /* padding: 8px 0px; */
          display: flex;
          align-items: center;
          color: #008FFC;
          cursor: pointer;
        }
        span.add-icon {
          font-size: 36px;
          margin-right: 6px;
        }
        .member-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 0;
          .setting-popover {
            .ant-popover-inner-content {
              padding:0
            }
          }
          .member-content {
            display: flex;
            align-items: center;
            .member-email {
              font-size: 12px;
            }
          }
          &:hover {
            .member-action {
              display: flex;
              &:focus {
                display: flex;
              }
            }
          }
          .member-action {
            display: none;
            font-size: 10px;
            width: 16px;
            height: 16px;
            background: rgba(0, 0, 0, 0.05);
            border-radius: 2px;
            // display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.setting-con {
  .setting-member-title {
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
      padding: 14px 16px;
      font-size: 16px;
      .title-icon {
          cursor: pointer;
      }
  }
  .role-item {
      display: flex;
      justify-content: space-between;
      padding: 9px 4px;
      cursor: pointer;
      .selected-icon {
          color: #008FFC;
      }
  }
  .setting-member-footer {
      display: flex;
      /* justify-content: center; */
      color: #FF5E5E;
      padding: 14px 16px;
      cursor: pointer;
      .remove-icon {
          margin-right: 5px;
      }
  }
  .divide-line {
      width: 100%;
      position: absolute;
      border-bottom: solid 1px rgba(0,0,0,0.2);
  }
}
</style>
